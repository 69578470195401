import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {ConfigProvider} from 'antd';
import {IntlProvider} from "react-intl";
import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";


import {
    fetchRecords,
    getUser,
    setApplicationAuthorization,
    setExpiresSignature,
    setInitUrl
} from "../../appRedux/actions";


import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import asyncComponent from "../../util/asyncComponent";
import {GET_USER_AUTHORIZATION, LOGIN_PAGE} from "../../constants/ServerUrl";

const RestrictedRoute = ({component: Component, location, token, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            token
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: location}
                    }}
                />}
    />;


const App = () => {

    const dispatch = useDispatch();
    const {locale, navStyle, layoutType} = useSelector(({settings}) => settings);
    const {token, initURL, authUser} = useSelector(({auth}) => auth);
    const {error, loading} = useSelector(({common}) => common);

    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();


    // useEffect(() => {
    //     let link = document.createElement('link');
    //     link.type = 'text/css';
    //     link.rel = 'stylesheet';
    //     link.href = "/css/style.css";
    //     link.className = 'gx-style';
    //     document.body.appendChild(link);
    // });

    useEffect(() => {
        if (initURL === '') {
            console.log("set init url");
            console.log(location.pathname);
            dispatch(setInitUrl(location.pathname));
        }
        const params = new URLSearchParams(location.search);

        if (params.has("signature") && params.has("expires")) {
            dispatch(setExpiresSignature({signature: params.get('signature'), expires: params.get('expires')}));
        }

        if (params.has("redirect_url")) {
            // console.log('this is the redirect url')
            // console.log(params.get('redirect_url'));
           //set Redirect url
           localStorage.setItem('redirect_url', params.get('redirect_url'));
        }


        //
        // if (params.has("theme")) {
        //     dispatch(setThemeType(params.get('theme')));
        // }
        // if (params.has("nav-style")) {
        //     dispatch(onNavStyleChange(params.get('nav-style')));
        // }
        // if (params.has("layout-type")) {
        //     dispatch(onLayoutTypeChange(params.get('layout-type')));
        // }
        // setLayoutType(layoutType);
        // setNavStyle(navStyle);
    }, [dispatch, initURL, layoutType, location.pathname, location.search, navStyle]);



    const getAuthorization = () => {
        fetchRecords(GET_USER_AUTHORIZATION).then((res) => {
            dispatch(setApplicationAuthorization(res.authorizations));
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (!loading) {

            dispatch(getUser());
            getAuthorization();
            if (location.pathname !== '/login' || location.pathname !== '/register' || location.pathname !== '/' || location.pathname !== '/password-reset') {
                if (!token) {

                    // setTimeout(() => {
                    //     console.log("pathname");
                    //     console.log(location.pathname);
                    //     if (location.pathname !== '/password-reset') {
                    //
                    //         if (!authUser) {
                    //
                    //            // history.push('/login');
                    //         }
                    //     }
                    //
                    // }, 2000);
                    //
                    // //history.push('/login');
                } else {
                    const redirectUrl = localStorage.getItem('redirect_url')
                    const token = localStorage.getItem('token')
                    if (redirectUrl) {
                        const tok = token.replace(/^"|"$/g, '');
                        const ref = `${redirectUrl}?referer=${tok}`;
                        localStorage.removeItem('redirect_url');
                        window.open(ref, '_blank');
                    }
                    //history.push(location.pathname);
                }
            }
        }
    }, [token, initURL]);

    const currentAppLocale = AppLocale[locale.locale];

    return (
        <ConfigProvider locale={currentAppLocale.antd}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>

                {/* <Switch>
                    <Route exact path='/' component={asyncComponent(() => import('../Welcome'))}/>
                    <Route exact path='/login' component={asyncComponent(() => import('../SignIn'))}/>
                    <Route exact path='/register' component={asyncComponent(() => import('../SignUp'))}/>
                    <Route exact path='/forgot-password' component={asyncComponent(() => import('../ResetPassword'))}/>
                    <Route exact path='/password-reset' component={asyncComponent(() => import('../NewPassword'))}/>
                    <Route exact path='/cookies-policy' component={asyncComponent(() => import('../CookiesPolicy'))}/>
                    <Route exact path='/employee-validations/:token' component={asyncComponent(() => import('../MonthlyValidation'))}/>
                    <Route path={`${match.url}`} component={MainApp}/>

                </Switch> */}


                <Switch>
                
                    
                    <Route exact path='/' component={asyncComponent(() => import('../Welcome'))}/>
                    <Route exact path='/login' component={asyncComponent(() => import('../SignIn'))}/>

                    
                    <Route exact path='/register' component={asyncComponent(() => import('../SignUp'))}/>
                    <Route exact path='/forgot-password' component={asyncComponent(() => import('../ResetPassword'))}/>
                    <Route exact path='/password-reset' component={asyncComponent(() => import('../NewPassword'))}/>
                    <Route exact path='/cookies-policy' component={asyncComponent(() => import('../CookiesPolicy'))}/>
                    {/* <Route exact path='/learning-center' component={asyncComponent(() => import('../LearningCenter'))}/> */}
                   
                    <Route path={`${match.url}`} component={MainApp}/>
                </Switch>
            </IntlProvider>
        </ConfigProvider>
    )
};

export default memo(App);
